<template>
  <v-footer
    tile
    height="54"
    class="font-weight-medium shadow-sm ma-4 rounded-lg"
    :color="$vuetify.theme.dark ? 'dark' : 'white'"
    :dark="$vuetify.theme.dark"
    :inset="true"
  >
    <v-col class="text-left" cols="12">
      <div class="d-flex flex-row justify-space-between align-center">
        <div class="d-flex align-center gap-2">
          <img
            v-if="$vuetify.theme.dark"
            height="12"
            alt="Voy Logo"
            src="/img/logo/voy-dark.png"
            class="unselectable non-draggable"
          />
          <img
            v-else
            height="12"
            alt="Voy Logo"
            class="unselectable non-draggable"
            src="/img/logo/voy-light.png"
          />

          <v-chip v-if="config.opMode" color="warning" label small>
            Modo técnico
          </v-chip>
        </div>
        <v-btn
          text
          x-small
          class="text--disabled"
          target="_blank"
          :href="`mailto:${email}`"
        >
          Contato
        </v-btn>
      </div>
    </v-col>
  </v-footer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { email } from "@/themeConfig.js";
export default {
  data: () => ({
    email,
  }),
  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapGetters("auth", ["config"]),
  },
};
</script>
